






















































































































































.header-layout {
  backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.6);
  margin: 0 auto;
  font-family: SuzukiPROBold;
  text-transform: uppercase;
  color: #000;

  .header-container {
    height: 60px;
    width: 90%;
    margin: 0 auto;
  }

  a {
    text-decoration: none;
    color: #000;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 12px;
  }

  .menu {
    display: flex;
    flex-direction: row;
    gap: 10px;
    height: 100%;

    .submenu {
      padding: 0 12px;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      cursor: pointer;

      &:hover {
        .autos-menu {
          display: grid;
        }
      }

      .autos-menu {
        display: none;
        position: absolute;
        background-color: #fff;
        left: 0;
        top: 100%;
        padding: 20px;
        z-index: 1000;
        width: max-content;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        height: auto;
        gap: 10px;

        .menu-title {
          margin-bottom: 20px;
        }

        > div {
          padding: 6px;
        }

        a {
          height: auto;
          font-family: SuzukiPRORegular;
          padding: 0;

          + a {
            margin-top: 7px;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .submenu-postventa {
      padding: 0 20px;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;

      &:hover {
        .postventa-menu {
          display: grid;
        }
      }

      .postventa-menu {
        display: none;
        position: absolute;
        background-color: #fff;
        right: 0;
        top: 100%;
        padding: 20px;
        z-index: 1000;
        width: max-content;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        height: auto;
        gap: 10px;

        > div {
          padding: 6px;
        }

        a {
          height: auto;
          text-transform: none;

          + a {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

.menu-mobile {
  background-color: #fff;
  width: 100vw;
  max-width: 320px;
  height: 100% !important;
  position: relative;
  padding-top: 60px;

  .btn-close {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  .q-item__label,
  .q-item__section--main {
    font-family: SuzukiPROBold;
  }
}
