@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';































































































































.footer-layout {
  color: #000;

  .footer-links {
    width: 90%;
    margin: 0 auto;
    padding: 30px 0 80px;
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    border-top: 1px solid #d9d8d9;

    h5 {
      font-size: 18px;
      line-height: 20px;
      font-family: SuzukiPROBold;
      margin: 0 0 20px;
    }

    a {
      font-family: SuzukiPRORegular;
      text-decoration: none;
      color: #000;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-transform: uppercase;

      + a {
        margin-top: 8px;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .footer-links-mobile {
    .q-item__label,
    .q-item__section--main {
      font-family: SuzukiPROBold;
    }
  }

  .social-media {
    width: 90%;
    margin: 0 auto;
    padding: 30px 0;
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    gap: 20px;
    border-top: 1px solid #d9d8d9;

    @media (max-width: $breakpoint-sm-max) {
      flex-flow: row wrap;
      border-top: none;
    }
  }

  .copyright {
    background-color: rgb(244, 244, 244);
    padding: 24px 0;
    font-family: SuzukiPRORegular;
    color: #001151;
    font-size: 14px;

    .container {
      width: 90%;
      margin: 0 auto;
    }
  }
}
